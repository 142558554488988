import React from "react"

// Components
import Seo from "components/seo"
import Hero from "../../components/hero"

// Page Sections
import List from "../screens/resources/case-study/list"

const CaseStudies = () => {
    return (
    <React.Fragment>
      <Hero pageTitle="Case Studies">
        <p>Detailed accounts of clinical and patient outcomes with Visby&nbsp;Medical use-cases.</p>
      </Hero>
      <List />
    </React.Fragment>
  )
}

export default CaseStudies

export const Head = () => (
  <Seo
        title="Case Studies"
        description="Detailed accounts of clinical and patient outcomes with Visby Medical use-cases."
        image="/meta/case-studies.jpg"
  />
)
